.footer {
    margin-top: 50px;
    background-color: var(--main-color);
    padding: 20px 0;
}

.footer__logo {
    height: 90px;
    display: block;
    margin: auto;
}

.footer__list {
    padding: 0;
    list-style: none;
    width: fit-content;
    margin: 10px auto;
    text-align: center;
}

.footer__item {
    display: block;
    margin-top: 10px;
}

.footer__linkItem {
    text-decoration: none;
    color: white;
    font-weight: 500;

    &:hover {
        color: var(--black);
    }
}

.footer__copyright {
    color: white;
    text-align: center;
    font-size: 14px;
    display: block;
    margin: auto;
}
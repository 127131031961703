@import url('https://fonts.googleapis.com/css2?family=Playwrite+AU+SA:wght@100..400&display=swap');
@import './Components/Header/Header.scss';

html {
  scroll-behavior: smooth;
}

:root {
  --main-color: #14c36e;
  --light-main-color: #01be62;
  --black: #000000;
  --white: #ffffff
}

.container {
  width: 90%;
  margin: 0 auto;
}

.accordionContainer {
  margin-top: 20px;
}

.imageBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  gap: 50px;
}

.imageBlock.reversed {
  flex-direction: row-reverse;
  margin-top: 70px;
}

.visionContent {
  width: 50%;
}

.grid-container {
  display: grid;
  grid-template-areas:
    "item1 item2"
    "item1 item3";
  gap: 20px;
  width: 50%;
}

.imageBlock__image {
  width: 50%;
  height: 400px;
  border-radius: 20px;
}

.grid-item {
  position: relative;
  overflow: hidden;
  // height: 10px;
  border-radius: 20px;
  box-shadow: 1px 10px 8px rgba(0, 0, 0, 0.1);
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  border-radius: inherit;
}

.item1 {
  grid-area: item1;
  height: auto;
  align-self: center;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.item2 {
  grid-area: item2;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}

.item3 {
  grid-area: item3;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
}

.fullContainer {
  background-color: #eaeaea69;
  width: 100%;
  padding: 40px 0;
  margin: 70px 0;
}

.event-detail {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px auto;
  max-width: 600px;
  border-top: 5px solid var(--main-color);
}

.event-detail-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.event-detail-description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.event-detail-time {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: gray;
}

.newsCards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}

.latestNews {
  font-family: "Playwrite AU SA", cursive;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .container {
    width: 95%;
  }

  .grid-container {
    width: 100%;
  }


  .imageBlock {
    flex-direction: column;
  }

  .imageBlock.reversed {
    flex-direction: column;
  }

  .visionContent {
    width: 100%;
  }

  .imageBlock__image {
    width: 100%;
  }

  .item1 {
    height: auto;
  }
}
.StorySubTitle {
    font-family: "Playwrite AU SA", cursive;
    color: rgb(200, 12, 12);
    font-size: 1rem;
    text-align: center;
    margin: auto;
}

.StoryTitle {
    text-align: center;
    font-size: 2rem;
    font-family: "Playwrite AU SA", cursive;
    margin: auto 0 20px;
}

.StoryText {
    width: 50%;
    margin: auto;
    font-size: 1.25rem;
    line-height: 40px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 768px) {
    .StoryText {
      width: 95%;
      font-size: 14px;
      line-height: 20px;
    }
  }
  
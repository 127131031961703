.newsCard {
    width: 400px;
    min-height: 300px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 10px 8px rgba(0, 0, 0, 0.1);
    border-top: 3px solid var(--main-color);
    padding: 20px;
}

.newsCard__content {
    font-size: 20px;
    line-height: 40px;
    color: rgba(30, 28, 28, 0.695);
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;

}

.newsCard__date {
    display: flex;
    align-items: center;
    color: grey;
}

.newsCard__icon {
    height: 30px;
    margin: 0 10px;
}

@media screen and (max-width: 768px) {
    .newsCard {
        width: 300px;
    }
}